import React from 'react'
import { CSSProp } from 'styled-components'
import tw, { css, styled } from 'twin.macro'

export interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  width?: string
  inline?: boolean
  insideInput?: boolean
  icon?: React.FC
  labelStyle?: CSSProp
}
const IconWrapper = tw.div` mr-4 size-2`
const LabelWrapper = tw.div`flex flex-row items-center`
const LabelStyles = styled.label<LabelProps>`
  ${tw`font-slussenLight`}
  ${css`
    cursor: inherit;
  `}
  ${({ width }) =>
    !!width &&
    css`
      width: ${width};
    `}
  ${({ insideInput }) =>
    insideInput
      ? tw`text-zevoyGray3 uppercase text-xs mb-2`
      : tw`p-1 text-greyBlue text-opacity-70 text-sm block mt-4`}
  ${({ labelStyle }) =>
    !!labelStyle &&
    css`
      ${labelStyle}
    `}
`

export const Label = ({
  width,
  inline,
  insideInput,
  children,
  icon: LabelIcon,
  labelStyle,
}: LabelProps) => {
  if (LabelIcon) {
    return (
      <LabelWrapper>
        <IconWrapper>
          <LabelIcon />
        </IconWrapper>
        <LabelStyles width={width} inline={inline} insideInput={insideInput}>
          {children}
        </LabelStyles>
      </LabelWrapper>
    )
  }
  return (
    <LabelStyles width={width} inline={inline} insideInput={insideInput} labelStyle={labelStyle}>
      {children}
    </LabelStyles>
  )
}
