import { FlattenSimpleInterpolation } from 'styled-components'
import tw, { css, theme } from 'twin.macro'
import { ComponentVariants } from '../../constants'
import { ButtonVariants } from './Buttons'

const primary = css`
  ${tw`
    bg-brightOrange text-white border-brightOrange
    hover:bg-brightOrangeHover
    disabled:opacity-30
    disabled:shadow-none
    disabled:hover:bg-brightOrange
  `}
`

const disabledPrimary = css`
  ${tw`
    bg-brightOrange text-white border-brightOrange
    opacity-30
    hover:cursor-not-allowed
  `}
`

const secondary = css`
  ${tw`
    bg-white text-zevoyBlue border-zevoyBlue
    hover:opacity-70
    disabled:bg-white
    disabled:text-zevoyGray4
    disabled:border-zevoyGray4
    disabled:hover:bg-white
  `}
`

const light = css`
  ${tw`
    bg-transparent text-white border-white hover:text-opacity-75 hover:border-opacity-75
  `}
`

const dark = css`
  ${tw`
    bg-zevoyBlueBlack text-white border-white hover:text-opacity-75 hover:border-opacity-75
  `}
`

const disabledSecondary = css`
  ${tw`
    bg-white text-zevoyGray4 border-zevoyGray4
    hover:cursor-not-allowed
  `}
`

const grey = css`
  ${tw`
    bg-zevoyGray2 text-white border-none
    hover:opacity-70
  `}
`

const borderDark = css`
  ${tw`
    bg-transparent text-zevoyBlueBlack border-zevoyBlueBlack
    hover:opacity-70
    disabled:text-zevoyBlueBlack
    disabled:border-zevoyGray4
  `}
`

const borderRed = css`
  ${tw`
    bg-white text-supportRed border-supportRed
    hover:opacity-70
    disabled:bg-white
    disabled:text-zevoyGray4
    disabled:border-zevoyGray4
    disabled:hover:bg-white
  `}
`

const borderGrey = css`
  ${tw`
    bg-white text-zevoyGray3 border-zevoyGray3
    hover:opacity-70
    disabled:bg-white
    disabled:text-zevoyGray4
    disabled:border-zevoyGray4
    disabled:hover:bg-white
  `}
`

const borderLess = css`
  ${tw`
    bg-white text-zevoyGray3 border-none
    hover:opacity-70
    disabled:bg-white
    disabled:text-zevoyGray4
    disabled:border-zevoyGray4
    disabled:hover:bg-white
  `}
`

const borderGradient = css`
  ${tw`
    text-zevoyBlueBlack hover:opacity-70 disabled:bg-white
    disabled:text-zevoyGray4
    disabled:border-zevoyGray4
    disabled:hover:bg-white
  `}
  border: 1px solid transparent;
  background: linear-gradient(to right, white, white),
    linear-gradient(
      to right,
      ${theme('colors.zevoyPurple.DEFAULT')},
      ${theme('colors.zevoyOrange')}
    );
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
`

const gradient = css`
  ${tw`
    text-zevoyWhite hover:opacity-70 disabled:bg-white
  `}
  border: none;
  background: linear-gradient(
    90deg,
    ${theme('colors.electricViolet')} 0%,
    ${theme('colors.brightOrange')} 100%
  );
  &:disabled {
    background: ${theme('colors.zevoyGray2')}; /* Override gradient with solid background */
  }
`

const positive = css`
  ${tw`
    text-zevoyBlueBlack hover:opacity-70 disabled:bg-white bg-zevoyPositive border-0
  `}
`

export const ButtonVariantStyles: Partial<Record<ButtonVariants, FlattenSimpleInterpolation>> = {
  [ComponentVariants.Primary]: primary,
  [ComponentVariants.DisabledPrimary]: disabledPrimary,
  [ComponentVariants.Secondary]: secondary,
  [ComponentVariants.DisabledSecondary]: disabledSecondary,
  [ComponentVariants.Light]: light,
  [ComponentVariants.Dark]: dark,
  [ComponentVariants.Grey]: grey,
  [ComponentVariants.BorderDark]: borderDark,
  [ComponentVariants.BorderRed]: borderRed,
  [ComponentVariants.BorderGrey]: borderGrey,
  [ComponentVariants.Borderless]: borderLess,
  [ComponentVariants.BorderGradient]: borderGradient,
  [ComponentVariants.Gradient]: gradient,
  [ComponentVariants.Positive]: positive,
}
