import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import tw, { css } from 'twin.macro'
import _uniqueId from 'lodash/uniqueId'
import { Sizes } from '../../constants'
import { Label } from '../Label'
import { Inline } from '../Spacing'

export type ToggleVariants = Exclude<Sizes, Sizes.ExtraLarge | Sizes.ExtraSmall | Sizes.Large>

const ToggleInput = tw.input`hidden`
export const ToggleLabel = styled.label<{
  checked?: boolean
  disabled?: boolean
  variant?: Sizes
}>`
  ${tw`
  cursor-pointer
  rounded-xl
  bg-greyBlue
  w-[39px]
  h-[22px]
  p-[2px]
  relative
  block
  transition-all
  box-border
  `}
  ${({ checked }) =>
    checked &&
    tw`
    bg-brightOrange
    `}
  ${({ disabled }) =>
    disabled &&
    tw`
    bg-disabledBackground
    `}
  ${({ disabled, checked }) =>
    disabled &&
    checked &&
    tw`
    bg-brightOrange opacity-50
    `}
  ${({ variant }) =>
    variant === Sizes.Small &&
    tw`
    w-[31px]
    h-[18px]
    p-[2px]
    `}
`

const checkedStyle = {
  [Sizes.Small]: tw`left-[14px]`,
  [Sizes.Medium]: tw`left-[18px]`,
}
const variantStyle = {
  [Sizes.Small]: tw`left-[2px] size-[14px]`,
  [Sizes.Medium]: tw`left-[2px] size-[18px]`,
}
export const ToggleTab = styled.span<{
  checked?: boolean
  disabled?: boolean
  variant: ToggleVariants
}>`
  ${tw`
absolute
bg-white
rounded-full
transition-all`}
  ${({ variant }) => variantStyle[variant]}
  ${({ checked, variant }) => checked && checkedStyle[variant]}
`

const ToggleCaptionLabel = tw(Label)`m-0`

export interface ToggleProps {
  label?: string
  initialValue: boolean
  disabled?: boolean
  variant?: ToggleVariants
  loading?: boolean
  onChange: (value: boolean) => void
}

export const Toggle = ({
  onChange,
  disabled,
  variant = Sizes.Medium,
  loading = false,
  initialValue = false,
  label,
}: ToggleProps) => {
  const [selected, setSelected] = useState<boolean>(initialValue)
  useEffect(() => setSelected(initialValue), [initialValue])

  const id = _uniqueId('toggle')
  const handleChange = () => {
    if (!loading) onChange(!selected)
    setSelected(!selected)
  }
  return (
    <Inline
      styled={css`
        ${tw`items-center gap-2`}
      `}
    >
      <ToggleInput
        id={id}
        type="checkbox"
        checked={selected}
        disabled={disabled}
        onChange={handleChange}
      />
      <ToggleLabel checked={selected} htmlFor={id} disabled={disabled} variant={variant}>
        <ToggleTab checked={selected} disabled={disabled} variant={variant} />
      </ToggleLabel>
      {label && <ToggleCaptionLabel>{label}</ToggleCaptionLabel>}
    </Inline>
  )
}
