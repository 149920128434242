import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const ErrorTitle = tw.div`text-3xl`
export const ErrorPageWrapper = tw.div`fixed overflow-x-hidden overflow-y-auto inset-0 flex justify-center items-center z-100`
export const ErrorMessage = tw.div`text-xl px-5 mb-5`
export const CloseButton = tw.div`py-5 pr-5 flex flex-row absolute right-0 top-0`
export const ErrorContentWrapper = tw.div`bg-white rounded-2xl text-center shadow-heavy flex-grow mx-20 overflow-y-auto pb-4 z-100 flex relative max-w-xl`

export const ErrorHeader = styled.h1<{ color?: string }>`
  ${tw`font-slussenSemibold text-4xl text-lightLabel leading-10 mb-6`}
  ${(props: any) =>
    props.color &&
    css`
      color: ${props.color};
      opacity: 1;
    `}
`

export const SupportEmailLink = tw.a`text-brightOrange`
export const RequestId = tw.p`text-xs m-0`
export const SupportText = tw.p`my-6`
export const ErrorWrapper = tw.div`relative max-w-lg text-left py-2`
export const ErrorFrame = tw.div`overflow-y-scroll text-[10px] rounded-lg w-full max-h-40 p-2 bg-lightGrey`
export const CopyButtonContainer = tw.div`absolute top-4 right-0 flex flex-row`
export const CopyButton = tw.button`top-0 right-0 p-2 flex flex-row gap-2 border-0 bg-transparent`

export const VerticallyAndHorizontallyCentered = styled.div<{
  inModal?: boolean
}>`
  ${tw`m-auto flex flex-col justify-center items-center py-4`}
  ${(props: any) =>
    !props.inModal &&
    css`
      ${tw`h-screen`}
    `}
`

export const ContentWrapper = tw.div`flex-grow w-full px-10 overflow-y-auto pb-4 h-screen`

export const ServerErrorMessage = tw.div`text-zevoyNegative`
