import React, { CSSProperties } from 'react'
import tw, { styled } from 'twin.macro'

const SpinnerWrapper = tw.div`w-full flex flex-col items-center justify-center text-primaryGrey font-slussenSemibold pt-20`
const Spinner = styled.svg`
  ${tw`animate-spin size-10`}
`

const SpinnerCircle = tw.circle`opacity-20 `
const SpinnerPath = tw.path`opacity-90`

const LegacyLoading = (props: {
  size?: string
  hideText?: boolean
  wrapperStyles?: CSSProperties
  spinnerStyles?: CSSProperties
  spinnerFill?: string
}) => {
  const { size, hideText, wrapperStyles, spinnerStyles, spinnerFill } = props
  const styles = size
    ? { width: `${size}px`, height: `${size}px`, ...wrapperStyles }
    : wrapperStyles
  return (
    <SpinnerWrapper style={styles}>
      <Spinner
        viewBox="0 0 24 24"
        width={spinnerStyles?.width}
        height={spinnerStyles?.height}
        style={spinnerStyles}
      >
        <SpinnerCircle cx="12" cy="12" r="10" stroke="#FFFFFF" fill="#FFFFFF" stoke-width="4" />
        <SpinnerPath
          fill={spinnerFill ?? '#8A8D8F'}
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </Spinner>
      {!hideText && 'Loading'}
    </SpinnerWrapper>
  )
}

export default LegacyLoading
